import React, { useEffect, useState } from 'react'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import { Link, useNavigate } from 'react-router-dom';
import { faTwitter, faFacebookF, faLinkedinIn, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getalldataregistration } from '../../store/registrationprofilefreelancer/action';
import { axiosApi } from '../../_helper/api_helper';
import { useDispatch, useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Logout from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ThreePIcon from '@mui/icons-material/ThreeP';
import CancelIcon from '@mui/icons-material/Cancel';
import "./header.scss"

import { Button, Popover } from '@mui/material';
import { Helmet } from 'react-helmet';











const Header = ({ points, recuiter_Points }) => {

    const history = useNavigate()
    const dispatch = useDispatch();
    const { getallRegistrationDetail, savepersonalDetailForm, getallRecurit } = useSelector((state) => state.RegistrationProfileReducer)
    const clientId = localStorage.getItem("client_Id");
    const recuiterId = localStorage.getItem("recuiter_Id");
    const country_name = localStorage.getItem(("country_name"));
    const [showAdditionalContent, setShowAdditionalContent] = useState(false);

    const toggleAdditionalContent = () => {
        setShowAdditionalContent(!showAdditionalContent);
    };
    const [personalDetail, setPersonalDetail] = useState({});
    const [recuiterProfile, setRecuiterProfile] = useState({})
    const [isFixed, setIsFixed] = useState(false);
    const [scrollDirection, setScrollDirection] = useState('none');
    const [showSecondaryHeader, setShowSecondaryHeader] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [notificationRecuiterData, setNotificationRecuiterData] = useState([]);

    const [notifyRecuiterUnreadCount, setNotifyRecuiterUnreadCount] = useState(null);
    const [notificationFreelancerData, setNotificationFreelancerrData] = useState([]);
    const [notifyFreelancerUnreadCount, setNotifyFreelancerUnreadCount] = useState(null);
    const [notifyanchor1, setNotifyAnchor1] = useState(null);
    const [freelancerAnchor1, setFreelancerAnchor1] = useState(null);

    const [fetchedData, setFetchedData] = useState(null);
    const [fetchDataFreelancer, setFetchDataFreelancer] = useState(null)



    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);


    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorE2);
    const open3 = Boolean(notifyanchor1);
    const open4 = Boolean(freelancerAnchor1);
    const idNotify = open3 ? 'simple-popover' : undefined;
    const idFreelancerNotify = open4 ? 'simple-popover' : undefined;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick2 = (event) => {
        setAnchorE2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorE2(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    useEffect(() => {
        const header = document.getElementById('header-second');

        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // const triggerPosition = document.documentElement.scrollHeight * 0.1;
            const triggerPosition = window.innerHeight * 0.3;

            if (scrollPosition > triggerPosition) {
                header.classList.add('header-fixed', 'header-red');
            } else {
                header.classList.remove('header-fixed', 'header-red');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // useEffect(() => {
    //     if (getallRegistrationDetail?.client && Object.keys(getallRegistrationDetail?.client).length > 0) {
    //         setPeronalDetailContent(getallRegistrationDetail?.client)
    //     }

    // }, [getallRegistrationDetail?.client])

    useEffect(() => {
        if (clientId) {
            axiosApi.get(`/clientdata/${clientId}`).then((response) => {
                setPersonalDetail(response.data.client);
            });
        }
    }, [clientId, points]);

    useEffect(() => {
        if (clientId) {
            dispatch(getalldataregistration(clientId))
            notificationFreelancer()
        }
    }, [clientId]);



    useEffect(() => {
        if (recuiterId) {
            recuiterData();

        }
    }, [recuiterId, recuiter_Points]);


    const recuiterData = async () => {
        if (recuiterId) {
            try {

                const response = await axiosApi(`/recuiter/${recuiterId}`);
                setRecuiterProfile(response?.data);



            } catch (error) {
                // Handle the error gracefully, for example:
                console.error("Error fetching recruiter data:", error);
                // Optionally, you can set a default or empty recruiter profile state here
                setRecuiterProfile(null); // or setRecuiterProfile({})
            }
        }
    }
    useEffect(() => {
        if (recuiterId) {
            notificationGet(recuiterId)
        }

    }, [recuiterId])

    const notificationGet = async (recuiterId) => {
        try {
            const response = await axiosApi.get(`/notifications/${recuiterId}`);
            const data = response.data;

            setFetchedData(data); // Store fetched data in state
            setNotifyRecuiterUnreadCount(data?.unread_count);


        } catch (error) {
            console.error("Error fetching notifications:", error);
        }

    }

    const notificationFreelancer = async () => {
        const response = await axiosApi.get(`/freelancer-notifications/${clientId}`);
        const data = response?.data;
        setFetchDataFreelancer(data)
        // setNotificationFreelancerrData(response.data?.notifications);
        setNotifyFreelancerUnreadCount(response.data?.unread_count || 0);
    }



    const handleNotifyClick = (event) => {
        setNotifyAnchor1(event.currentTarget);
        if (fetchedData) {

            setNotificationRecuiterData(fetchedData.notifications);

            setNotifyRecuiterUnreadCount(0);


        }
    }
    const handleCloseNotify = () => {
        setNotifyAnchor1(null);
    };
    const handleNotifyFreelancerClick = (event) => {
        setFreelancerAnchor1(event.currentTarget);
        if (fetchDataFreelancer) {
            setNotificationFreelancerrData(fetchDataFreelancer?.notifications);
            setNotifyFreelancerUnreadCount(0);
        }
    }
    const handleCloseFreelancerNotify = () => {
        setFreelancerAnchor1(null);
    };





    useEffect(() => {
        let prevScrollPos = window.scrollY;

        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const triggerPosition = window.innerHeight * 0.3;

            setScrollDirection(
                currentScrollPos > prevScrollPos ? 'down' : 'up'
            );

            prevScrollPos = currentScrollPos;

            // Adjust the threshold value based on when you want the secondary header to appear
            const showHeaderThreshold = 2;
            setShowSecondaryHeader(currentScrollPos > showHeaderThreshold);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            const triggerPosition = window.innerHeight * 0.3;
            if (window.scrollY > triggerPosition) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const ProfilePicURL = `${axiosApi.defaults.baseURL}${personalDetail?.profile_pic}`;
    const RecuiterProfileUrl = `${axiosApi.defaults.baseURL}${recuiterProfile?.profile_pic}`;


    const handleRemoveStorage = () => {
        localStorage.clear();

        history("/")
        window.location.reload()
    }


    const handleNavigate = (data) => {
        history(data)
    }


    const calculateTimeElapsed = (createdAt) => {
        const currentDate = new Date();
        const postedDate = new Date(createdAt);
        const timeDifference = currentDate - postedDate;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (years > 0) {
            return `${years} ${years === 1 ? 'year' : 'years'} ago`;
        } else if (months > 0) {
            return `${months} ${months === 1 ? 'month' : 'months'} ago`;
        } else if (weeks > 0) {
            return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
        } else if (days > 0) {
            return `${days} ${days === 1 ? 'day' : 'days'} ago`;
        } else if (hours > 0) {
            return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
        } else if (minutes > 0) {
            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
        } else {
            return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
        }
    };

    return (
        <>
            <Helmet>


                <meta name="p:domain_verify" content="598236f1843f8e7d36257d67e45a29ce" />

            </Helmet>
            <nav className='headerfirst' fluid={true} style={{ overflow: "auto", }}>
                <div className='headerfirst-inner'>
                    <div className='  headerfirst-inner-flexfirst'>

                        <Link target='_blank' to="https://www.facebook.com/nearbyfreelancer">

                            <FontAwesomeIcon className='header-icon' icon={faFacebookF}
                            />
                        </Link>


                        {/* <div  ><FontAwesomeIcon icon={faTwitter}
                            style={{ background: 'white', padding: "3px", fontSize: '10px', borderRadius: '50%', width: "20px", height: "20px" }}
                            className=' ' /> </div> */}

                        <Link target='_blank' to="https://www.linkedin.com/company/nearbyfreelancers/">

                            <FontAwesomeIcon icon={faLinkedinIn}
                                className='header-icon'
                            />
                        </Link>




                        <Link target='_blank' to="https://www.instagram.com/nearbyfreelancers?igsh=MWg0cGhkZmliYWY4eQ==">


                            <FontAwesomeIcon icon={faInstagram}
                                className='header-icon'
                            />
                        </Link>
                        <Link target='_blank' to="https://www.youtube.com/@nearbyfreelancers">


                            <FontAwesomeIcon icon={faYoutube}
                                className='header-icon'
                            />
                        </Link>


                    </div>
                    <div className='headerfirst-inner-second '>
                        {/* <div className='header-support' >Support : +91 7982717460 </div> */}
                        {/* <div className='header-call' >Contact Us : +91 9625318281 </div> */}
                        <div className='header-email'  >Email ID : info@nearbyfreelancers.com</div>
                    </div>
                </div>
            </nav >

            <div id="header-second" className="header-second">
                <div className="headersecond-container">
                    <div className='headersecond-img'>
                        <Link to='/'>
                            <img src={"/image/nbflogo.png"} alt="Logo" />
                        </Link>
                    </div>
                    <div className='header-rightside'>
                        <div className='header-post-project'>

                            {recuiterId ? (
                                <>
                                    <Link style={{ textDecoration: "none", color: isScrolled ? "white" : "black", border: isScrolled ? "1px solid white" : "1px solid black", borderRadius: "58px", padding: "8px 16px", fontSize: "16px" }} to='/project-posting'>

                                        Post Project

                                    </Link>

                                    <Link style={{ textDecoration: "none", color: isScrolled ? "white" : "black", border: isScrolled ? "1px solid white" : "1px solid black", borderRadius: "58px", padding: "8px 16px", fontSize: "16px", marginLeft: "8px" }} to='/recuiter-profile/post_a_job'>

                                        Post Job

                                    </Link>

                                </>


                            ) : (
                                null
                                // <Link style={{ textDecoration: "none", color: isScrolled ? "white" : "black", border: isScrolled ? "1px solid white" : "1px solid black", borderRadius: "58px", padding: "8px 16px", fontSize: "16px" }} to='/job-recuiter-login'>

                                //     Hire Talent

                                // </Link>
                            )}

                        </div>
                        <div className='credit-header-side'>
                            {clientId ? (

                                <div style={{ textDecoration: "none", background: isScrolled ? "white" : "white", color: isScrolled ? "black" : "black", border: isScrolled ? "1px solid white" : "1px solid black", borderRadius: "53px", padding: "8px 16px", }} className='credits-points-freelancer'>
                                    {/* <Link to="/credits"> */}
                                    {clientId && (<>
                                        <img src="/svg/wallet.svg" alt="" />
                                        <span>

                                            <b> {personalDetail?.point}</b>

                                        </span> </>)}
                                    {/* </Link> */}



                                </div>


                            ) : recuiterId ? (
                                 country_name === "India" && (
                                    <div style={{ textDecoration: "none", background: isScrolled ? "white" : "white", color: isScrolled ? "white" : "black", border: isScrolled ? "1px solid white" : "1px solid black", borderRadius: "53px", padding: "6px 12px", }} className='credits-points-freelancer'>
                                        <Link to="/credits-plan">
                                            {recuiterId && (<>
                                                <img src="/svg/wallet.svg" alt="" />
                                                <span>

                                                    <b> {recuiterProfile?.point}</b>

                                                </span> </>)}
                                        </Link>



                                    </div>
                                ) 

                            ) : (null
                                // <Link className='become-freelancer-header' style={{ textDecoration: "none", color: isScrolled ? "white" : "black", border: isScrolled ? "1px solid white" : "1px solid black", borderRadius: "58px", padding: "8px 16px", fontSize: "16px" }} to='/register-as-freelancer'>

                                //     Become Freelancer

                                // </Link>

                            )}
                        </div>
                        {recuiterId || clientId ? null :
                            <Link className='ms-1 post-project-mobile' style={{ textDecoration: "none", color: isScrolled ? "white" : "black", border: isScrolled ? "1px solid white" : "1px solid black", borderRadius: "58px", padding: "8px 16px", fontSize: "16px", marginLeft: "8px" }} to='/project-post'>

                                Post  Project

                            </Link>}
                        {recuiterId || clientId ? null :
                            <Link className='ms-1 post-project-mobile' style={{ textDecoration: "none", color: isScrolled ? "white" : "black", border: isScrolled ? "1px solid white" : "1px solid black", borderRadius: "58px", padding: "8px 16px", fontSize: "16px", marginLeft: "8px" }} to='/job-post'>

                                Post  Job

                            </Link>}
                        {recuiterId || clientId ? null :
                            <div class="dropdown">
                                <a style={{ background: "#FF3131", color: "white" }} class="btn btn  dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Login
                                </a>

                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><Link class="dropdown-item" to="/register-as-freelancer">Become Freelancer</Link></li>
                                    <li><Link class="dropdown-item" to="/job-recuiter-login">Hire Talent</Link></li>

                                </ul>
                            </div>
                        }




                        <div className="header-notification">
                            {clientId ? (
                                <>
                                    <IconButton
                                        size="large"
                                        aria-label={`show ${notifyFreelancerUnreadCount} new notifications`}
                                        color="inherit"
                                        onClick={handleNotifyFreelancerClick}
                                    >
                                        <Badge badgeContent={notifyFreelancerUnreadCount} color="error">
                                            <NotificationsIcon style={{ color: "#FFDB00" }} />
                                        </Badge>
                                    </IconButton>
                                    <Popover
                                        id={idFreelancerNotify}
                                        open={open4}
                                        anchorEl={freelancerAnchor1}
                                        onClose={handleCloseFreelancerNotify}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <div style={{ padding: '10px', maxWidth: '300px' }}>
                                            {notificationFreelancerData?.length > 0 ? (
                                                notificationFreelancerData.map((notification) => (
                                                    <div key={notification.id} style={{ marginBottom: '10px', display: "flex" }}>
                                                        <Typography width={'240px'} variant="body2" fontSize={'12px'}>
                                                            {notification.message}
                                                        </Typography>
                                                        <Typography variant="caption" width={'60px'} style={{ fontSize: "10px" }} color="textSecondary">
                                                            {/* {new Date(notification.created_at).toLocaleString()} */}
                                                            {calculateTimeElapsed(notification?.created_at)}
                                                        </Typography>
                                                    </div>
                                                ))
                                            ) : (
                                                <Typography variant="body2">No notifications</Typography>
                                            )}
                                        </div>
                                    </Popover>
                                </>
                            ) : null}
                            {recuiterId ? (
                                <>
                                    <IconButton
                                        size="large"
                                        aria-label={`show ${notifyRecuiterUnreadCount} new notifications`}
                                        color="inherit"
                                        onClick={handleNotifyClick}
                                    >
                                        <Badge badgeContent={notifyRecuiterUnreadCount} color="error">
                                            <NotificationsIcon style={{ color: "#FF9800" }} />
                                        </Badge>
                                    </IconButton>
                                    <Popover
                                        id={idNotify}
                                        open={open3}
                                        anchorEl={notifyanchor1}
                                        onClose={handleCloseNotify}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <div style={{ padding: '10px', maxWidth: '300px' }}>
                                            {notificationRecuiterData?.length > 0 ? (
                                                notificationRecuiterData.map((notification) => (
                                                    <div key={notification.id} style={{ marginBottom: '10px', display: "flex" }}>
                                                        <Typography width={'240px'} variant="body2" fontSize={'12px'}>
                                                            <Link style={{ textDecoration: "none", color: "black" }} to={`/Freelancer/${notification?.freelancer}`}>{notification.message}</Link>

                                                        </Typography>
                                                        <Typography width={'60px'} style={{ fontSize: "10px" }} variant="caption" color="textSecondary">
                                                            {/* {new Date(notification.created_at).toLocaleString()} */}
                                                            {calculateTimeElapsed(notification?.created_at)}
                                                        </Typography>
                                                    </div>
                                                ))
                                            ) : (
                                                <Typography variant="body2">No notifications</Typography>
                                            )}
                                        </div>
                                    </Popover>
                                </>
                            ) : null}
                        </div>



                        <div className="header-profile-account">
                            {recuiterId ? <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleClick2}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        {recuiterProfile?.profile_pic ? (
                                            <img style={{ width: "40px", height: "40px", border: "1px solid lightGrey", borderRadius: "50%", objectFit: "contain" }} src={RecuiterProfileUrl} alt="" />
                                        ) : (
                                            <AccountCircleIcon style={{ fontSize: "35px" }} />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </Box> : null}
                            {clientId ? <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        {personalDetail?.profile_pic ? (
                                            <img style={{ width: "40px", height: "40px", border: "1px solid lightGrey", borderRadius: "50%", objectFit: "contain" }} src={ProfilePicURL} alt="" />
                                        ) : (
                                            <AccountCircleIcon style={{ fontSize: "35px" }} />
                                        )}



                                    </IconButton>
                                </Tooltip>
                            </Box> : null}
                        </div>

                        <div className='header-hamburger' onClick={toggleAdditionalContent}><FormatAlignRightIcon /></div>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            {personalDetail?.status == 0 ?
                                <MenuItem onClick={() => handleNavigate("/profile")} >

                                    <ThreePIcon style={{ coloe: "gray" }} /> <span className='ms-2'>Profile</span>



                                </MenuItem> :
                                <MenuItem onClick={() => handleNavigate("/freelancer-dashboard")} >

                                    <ThreePIcon style={{ coloe: "gray" }} /> <span className='ms-2'>Profile</span>



                                </MenuItem>
                            }

                            {/* <MenuItem onClick={() => handleNavigate("/refer-to-earn")}>
                                <AccountBalanceIcon style={{ coloe: "gray" }} /> <span className='ms-2'>Refer To Earn</span>
                            </MenuItem> */}
                            <Divider />

                            <MenuItem onClick={handleRemoveStorage}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                        <Menu
                            anchorEl={anchorE2}
                            id="account-menu"
                            open={open2}
                            onClose={handleClose2}
                            onClick={handleClose2}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={() => handleNavigate("/recuiter-profile")} >

                                <ThreePIcon style={{ coloe: "gray" }} /> <span className='ms-2'>Profile</span>



                            </MenuItem>


                            <MenuItem onClick={handleRemoveStorage}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>



                    </div>
                </div>
            </div>

            {/* <div className="header-mobile">
                <div className="header-mobile-container">
                    <div className='headermobile-img'><Link to='/'><img src="/image/nbflogo.png" alt="" /></Link></div>
                    {recuiterId ? <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

                        <div class="btn-group">
                            <button type="button" class="btn btn-danger dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                Action
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a class="dropdown-item" href="#">Separated link</a></li>
                            </ul>
                        </div>
                    </Box> : null}
                    <div onClick={toggleAdditionalContent}><FormatAlignRightIcon /></div>
                </div>
            </div > */}

            <div className="additional-content " style={{ display: showAdditionalContent ? 'block' : 'none' }}>
                <div className="additional-content-inner">
                    <div> <CancelIcon onClick={toggleAdditionalContent} /></div>
                    <ul>
                        <li>
                            {recuiterId &&
                                (recuiterProfile?.profile_pic ? (
                                    <img style={{ width: "40px", height: "40px", border: "1px solid lightGrey", borderRadius: "50%", objectFit: "contain" }} src={RecuiterProfileUrl} alt="" />
                                ) : (
                                    <AccountCircleIcon style={{ fontSize: "35px" }} />
                                ))
                            }
                            {clientId && (
                                personalDetail?.profile_pic ? (
                                    <img style={{ width: "40px", height: "40px", border: "1px solid lightGrey", borderRadius: "50%", objectFit: "contain" }} src={ProfilePicURL} alt="" />
                                ) : (
                                    <AccountCircleIcon style={{ fontSize: "35px" }} />
                                )
                            )}


                        </li>
                        <li>
                            {recuiterId ? (
                                <Link style={{ textDecoration: "none" }} to='/recuiter-profile/overview-project'>

                                    Dashobard  Profile

                                </Link>

                            ) : clientId ? (

                                <Link style={{ textDecoration: "none" }} to='/freelancer-dashboard/overview-project'>

                                    Dashobard  Profile

                                </Link>
                            ) : null}
                        </li>
                        <li>
                            {recuiterId ? (
                                <Link style={{ textDecoration: "none" }} to='/recuiter-profile/edit_profile'>

                                    Edit  Profile

                                </Link>

                            ) : clientId ? (

                                <Link style={{ textDecoration: "none" }} to='/freelancer-dashboard/freelancer_profile'>

                                    Edit  Profile

                                </Link>
                            ) : null}
                        </li>
                        <li>
                            {recuiterId ? (
                                <Link style={{ textDecoration: "none" }} to='/view-all-freelancer'>

                                    Find Freelancers

                                </Link>

                            ) : clientId ? (

                                <Link style={{ textDecoration: "none" }} to='/view-all-projects'>

                                    Find Projects

                                </Link>
                            ) : <Link style={{ textDecoration: "none" }} to='/register-as-freelancer'>

                                Become Freelancer

                            </Link>}
                        </li>

                        <li>
                            {clientId ? (

                                <Link style={{ textDecoration: "none" }} to='/hire-talent'>

                                    Find Job

                                </Link>




                            ) : recuiterId ? (
                                <Link style={{ textDecoration: "none" }} to='/project-posting'>

                                    Post Project

                                </Link>
                            ) : <Link style={{ textDecoration: "none" }} to='/job-recuiter-login'>

                                Hire Talent

                            </Link>}
                        </li>
                        <li>
                            {recuiterId ? (
                                <Link style={{ textDecoration: "none" }} to='/project-posting'>

                                    Post Job

                                </Link>

                            ) : clientId ? (

                                <Link style={{ textDecoration: "none" }} to='/credits'>

                                    Plan

                                </Link>
                            ) : <Link style={{ textDecoration: "none" }} to='/job-recuiter-login'>

                                Post Project/Job

                            </Link>}
                        </li>
                        <li>
                            {recuiterId ? (
                                <Link style={{ textDecoration: "none" }} to='/credits-plan'>

                                    Plain

                                </Link>

                            ) : clientId ? (

                                <Link style={{ textDecoration: "none" }} to='/job-recuiter-login'>

                                    Hire Talent

                                </Link>
                            ) : null}
                        </li>
                        <li><Link to='/about-us'>About us</Link></li>
                        <li><Link to='/contact-us'>Contact us</Link></li>

                        <li><Link to='/blog'>Blog</Link></li>
                        <li onClick={handleRemoveStorage}> Log Out</li>

                    </ul>
                </div>



            </div>


        </>
    )
}

export default Header


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";
 
import { GoogleOAuthProvider } from '@react-oauth/google';



ReactGA.initialize("G-NTJHTPLDG8");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
  title: "Near By Freelancer"
});

// Create the root element for React 18
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider
    // domain="dev-nsqek6ix77yglj1v.us.auth0.com"
    clientId="1010031458079-e3cn0noniskeanhmunc75n8iqpu2m0kp.apps.googleusercontent.com"
     
  >
    <App />
  </GoogleOAuthProvider>,
 
);
 
reportWebVitals();

import React, { useEffect, useState } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import "./loadingpannel.scss"
const LoadingPannel = () => {
    

    

    return (
        <div className="loading-wrapper">
         
                <RotatingLines
                    visible={true}
                    height="76"
                    width="76"
                    color="red"
                    strokeWidth="3"
                    strokeColor='red'
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass="loading-pannel"
                    className='loading-pannel'
                />
           
        </div>

    )
}
    
export default LoadingPannel
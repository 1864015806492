import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosApi } from '../../_helper/api_helper';
import Header from '../../Layout/Header/Index';
import Footer from '../../Layout/Footer/Index';
import VerifiedIcon from '@mui/icons-material/Verified';
import "./successpaymentglobal.scss"

const SuccessPaymentGlobal = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const client_Id = localStorage.getItem('client_Id');
    const query = new URLSearchParams(location.search);
    const [points, setPoints] = useState(null);
    const razorpay_payment_id = query.get('razorpay_payment_link_id');

    useEffect(() => {

        const query = new URLSearchParams(location.search);
        const razorpay_payment_id = query.get('razorpay_payment_link_id');
         

        if (razorpay_payment_id) {

            // Make API call to the backend
            axiosApi.get(`/check-payment-status-it/${razorpay_payment_id}`)
                .then(response => {

                     setTimeout(() => {
                        navigate('/')
                     }, 1000);
                    // Handle success scenario
                })
                .catch(error => {
                    console.error("Error in payment callback", error);
                    // Handle error scenario
                });
        }
    }, [location]);
    useEffect(() => {
        if (client_Id) {
            updatePoints();
        }
    }, [client_Id]);
    const updatePoints = () => {
        axiosApi.get(`/clientdata/${client_Id}`).then((response) => {
            setPoints(response.data.client.point);
        });
    };

    return (
        <>
            <Header points={points}  />
            <div className='success-payment'>
                <div className="success-payment-inner">
                    <div className='payment-icon-contianer'>
                        <VerifiedIcon className='payment-icon' />
                    </div>
                    <div><h4>Payment Successfully Done</h4></div>
                    <div>Transaction Id : {razorpay_payment_id}</div>

                </div>

            </div>
            <Footer />

        </>
    );
};


export default SuccessPaymentGlobal

import React from 'react';
import "./globaljobdone.scss";
import CelebrationIcon from '@mui/icons-material/Celebration';
import { Link } from 'react-router-dom';


const GlobaljobSubmiitted = () => {
  return (
    <div className="global-project-interested">
    <div className="global-container">
        <div className="interested-content">
            <div className='interested-image'>
                <CelebrationIcon style={{fontSize:"80px",color:"red"}}/>
                {/* <img src="image/profilecompleted.png" alt="" /> */}
                </div>
            <div className='profile-intrested-heading'><b><h3>Congratulations, Your job has been submitted with us .</h3></b></div>
            <div className='profile-intrested-para'>Your Profile is  under review and we will get back to you  shortly.</div>
            <div><Link to='/recuiter-profile/all_jobs'><button className='btn btn-dark'>Go to Dashboard</button></Link> </div>
            
        </div>
    </div>
</div>
  )
}
export default GlobaljobSubmiitted

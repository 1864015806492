import React, { useEffect, useRef, useState } from 'react'
import "./projectpostlogin.scss";
import { axiosApi } from '../../_helper/api_helper';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import { TextField, Tooltip, Typography } from '@mui/material';
import { toast, ToastContainer } from "react-toastify";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingPannel from '../../commonComponent/LoadingPannel/LoadingPannel';


const ProjectPostLogin = () => {

    const recuiterId = localStorage.getItem('recuiter_Id');
    const navigate = useNavigate();

    
    const [currentPage, setCurrentPage] = useState(0);

    const [industries, setIndustries] = useState([]);
    const [citiesGetData, setCitiesGetData] = useState([])
    const [subcategories, setSubcategories] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState("");
    const [payHour, setPayHour] = useState(false);
    const [fixedPrice, setFixedPrice] = useState(false);
    const [manySkills, setManySkill] = useState([]);
    const [skill, setSkill] = useState('');
    const [validationMsg, setValidationMsg] = useState('');

    const [loading, setLoading] = useState(false)
    const [formValues, setFormValues] = useState({
        recuiter_name: '',
        project_title: '',
        project_description: '',
        country_name: "Australia",
        skills: [],
        project_budget_hr: "",
        project_budget: "",
        skill: "",
        occupation_skill: "",
        Occupation_industry: "",
        max_perhour_usd: '',
        min_perhour_usd: '',
        max_fixedprice_usd: '',
        min_fixedprice_usd: '',
        number_of_hours: '',
        city_new: '',
        max_perhour: '',
        min_perhour: '',
        max_fixedprice: '',
        min_fixedprice: '',
    });
    const [selectedIndustryName, setSelectedIndustryName] = useState('');
    const [selectedSkillName, setSelectedSkillName] = useState('');
   


    const [countries, setCountries] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const filterCountry = countries.find((d) => d.country_name == formValues?.country_name)?.id
    const totalPages = 4;


    const [projectdescp, setProjectDescp] = useState({
        input_prompt: ""
    });
    const [apiResponse, setApiResponse] = useState(null); // To hold the API response



    const handleDecpChange = (e) => {
        setProjectDescp({ ...projectdescp, [e.target.name]: e.target.value });
    };

    const descriptionapi = async () => {

        setLoading(true)

        try {

            const payload = {
                input_prompt: projectdescp?.input_prompt
            }

            const response = await axiosApi.post(`/generate-project-discription`, payload);
            setApiResponse(response.data);

            setFormValues((prevValues) => ({
                ...prevValues,
                // project_title: response.data?.title || '',
                project_description: response.data?.description || '',
            }));
            const result = await axiosApi.post(`/generate-project-title`, payload);
            setApiResponse(response.data);

            setFormValues((prevValues) => ({
                ...prevValues,
                project_title: result.data?.title || '',
                // project_description: result.data?.description || '',
            }));



            setLoading(false)


            // console.log("projectDecription::::::", response?.data);
        } catch (error) {
            toast.error('Please type few points.', { position: 'top-left' });
            setLoading(false)

            console.error("Error fetching description:", error);
        }
    }

    const handleGenerateSkills = async () => {
        const payload = {
            industry: selectedIndustryName,
            subcategory: selectedSkillName,
        };

        console.log("Payload to send:", payload); // For debugging

        try {
            const response = await axiosApi.post(`/generate-skills`, payload);

            console.log("reponsegenreateskill", response?.data)
            setManySkill(response?.data?.skills)
        } catch (error) {
            console.error("Error:", error);
        }
    };


    const handleConfirmation = (useApiData) => {
        if (!useApiData) {
            // Clear fields for manual editing
            setFormValues((prevValues) => ({
                ...prevValues,
                project_title: '',
                project_description: '',
            }));
            // User wants to edit manually
        }
    };


    const handleChangeskill = (e) => {
        setSkill(e.target.value);
    };
    const handleskillSoftware = (e) => {
        e.preventDefault();
        if (skill.trim()) {
            setManySkill([...manySkills, skill]);
            setSkill('');
        }
    };


    const handleSkillDelete = (index) => {
        // const newSkills = manySkills.filter((_, i) => i !== index);
        // setManySkill(newSkills);
        const updatedSkills = manySkills.filter((_, i) => i !== index);
        setManySkill(updatedSkills);
    };
    const handlePerhours = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');


        setFormValues({ ...formValues, [e.target.name]: numericValue })
    }
    const textareaRef = useRef(null);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        adjustHeight();
    };
    const adjustHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = '10px'; // Reset the height first
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set it to scrollHeight to fit content
        }
    };


    const handleNext = async () => {
        if (currentPage === 1 && !apiResponse) {
            // Only call the API if on page 1 and there is no response yet
            await descriptionapi();
        } else if (validatePage()) {
            // Move to the next page if validation passes
            setCurrentPage(currentPage + 1);
            await handleGenerateSkills();
        }
    };
    // const handleNext = async () => {

    //     if (validatePage()) {
    //         setCurrentPage(currentPage + 1);
    //         await handleGenerateSkills();
    //     }
    // };
    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };
    useEffect(() => {

        citiesGet();
        countriesApi()
    }, [])
    const citiesGet = async () => {
        const response = await axiosApi.get("/cities");
        setCitiesGetData(response?.data)
    }
    const countriesApi = async () => {
        const response = await axiosApi.get("/countries");
        setCountries(response?.data)
    }

    const countriesGetById = async () => {
        try {
            const response = await axiosApi.get(`/countries/${filterCountry}/cities`);
            // const sortedData = response?.data?.sort((a, b) =>
            //     a.city_name.localeCompare(b.city_name)
            // );  
            const sortedData = response?.data?.sort((a, b) => {
                // Check if one of the items is "Others" and move it to the end
                if (a.city_name === "Others") return 1;
                if (b.city_name === "Others") return -1;


                return a.city_name.localeCompare(b.city_name);
            });
            setCountryData(sortedData);
        } catch (error) {
            console.error('Error fetching country data:', error);
        }
    };

    useEffect(() => {
        if (filterCountry) {
            countriesGetById();
        }

    }, [filterCountry])
    useEffect(() => {
        IndustriesGet();

    }, [])
    const IndustriesGet = async () => {
        const response = await axiosApi.get("/industries");
        setIndustries(response?.data)
    }


    const handleIndustryChange = (e) => {
        const selectedIndustryId = e.target.value;
        const selectedIndustry = industries.find(industry => industry.id === selectedIndustryId);

        setSelectedIndustryName(selectedIndustry ? selectedIndustry.name : '');
        setFormValues({ ...formValues, industry: selectedIndustryId });

        if (selectedIndustryId) {
            axiosApi.get(`/industries/${selectedIndustryId}`)
                .then(response => {
                    setSubcategories(response.data.occupation_skills || []);
                })
                .catch(error => {
                    console.error("There was an error fetching the subcategories!", error);
                    setSubcategories([]);
                });
        } else {
            setSubcategories([]);
        }
    };
    const handleCategoryChange = (e) => {
        const selectedSkillName = e.target.value;
        setSelectedSkillName(selectedSkillName);
        setFormValues({ ...formValues, occupation_skill: selectedSkillName });
    };

    const HandlePayHour = () => {
        setPayHour(true);
        setFixedPrice(false);
        // setCustomBudget(false);
        // setEstimateBudget(false);
    }
    const handleFixedPrice = (e) => {
        setFixedPrice(true);
        setPayHour(false);
        // setCustomBudget(false);
        // setEstimateBudget(false);
    }
    const handleMinBudgetChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        const { name, value } = e.target;
        const parsedValue = parseFloat(numericValue);

        // Update the form values state
        setFormValues(prevState => ({
            ...prevState,
            [name]: numericValue
        }));



    };
    const handleMaxBudgetChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        const { name, value } = e.target;


        // Update the form values state
        setFormValues(prevState => ({
            ...prevState,
            [name]: numericValue
        }));


    };

    const validateMinBudget = (value) => {
        const parsedValue = parseFloat(value);

        // Show error only if the user finishes entering the value and it's invalid
        if (isNaN(parsedValue) || parsedValue < 100) {
            toast.error('Please fill minimum budget with a value greater than or equal to 100.');
        }
    };
    const validateMinBudgetGlobal = (value) => {
        const parsedValue = parseFloat(value);

        // Show error only if the user finishes entering the value and it's invalid
        if (isNaN(parsedValue) || parsedValue < 10) {
            toast.error('Please fill minimum budget with a value greater than or equal to US$10.');
        }
    };

    const validateMaxBudget = (value) => {
        const parsedValue = parseFloat(value);
        const minBudget = parseFloat(formValues.min_fixedprice || 0);

        // Show error only after the user finishes typing
        if (isNaN(parsedValue) || parsedValue <= minBudget) {
            toast.error('Maximum budget should be greater than the minimum budget.');
        }
    };
    const validateMaxBudgetGlobal = (value) => {
        const parsedValue = parseFloat(value);
        const minBudget = parseFloat(formValues.min_fixedprice_usd || 0);

        // Show error only after the user finishes typing
        if (isNaN(parsedValue) || parsedValue <= minBudget) {
            toast.error('Maximum budget should be greater than the minimum budget.');
        }
    };




    const handleSubmit = async (data) => {
        setLoading(true);



        try {


            const payloadProject = {
                // recuiter_name: formValues.recuiter_name,
                project_title: formValues.project_title,
                project_description: formValues.project_description,
                skills: manySkills.map(skill => ({ name: skill })),
                industry: selectedIndustryName,
                occupation_skill: selectedSkillName,
                city_new: formValues?.city_new,
                country_name: formValues?.country_name
            };
            if (formValues.country_name === "India") {
                if (payHour) {
                    payloadProject.min_perhour = Number(formValues.min_perhour);
                    payloadProject.max_perhour = Number(formValues.max_perhour);
                    payloadProject.number_of_hours = Number(formValues.number_of_hours);
                } else if (fixedPrice) {
                    payloadProject.min_fixedprice = Number(formValues.min_fixedprice);
                    payloadProject.max_fixedprice = Number(formValues.max_fixedprice);
                }
            } else {
                if (payHour) {
                    payloadProject.min_perhour_usd = Number(formValues.min_perhour_usd);
                    payloadProject.max_perhour_usd = Number(formValues.max_perhour_usd);
                    payloadProject.number_of_hours = Number(formValues.number_of_hours);
                } else if (fixedPrice) {
                    payloadProject.min_fixedprice_usd = Number(formValues.min_fixedprice_usd);
                    payloadProject.max_fixedprice_usd = Number(formValues.max_fixedprice_usd);
                }
            }
            const result = await axiosApi.post(`/multijob/${recuiterId}`, payloadProject);
            // alert("Project submit successfully")
            navigate("/recuiter-profile/my_project");
            axiosApi.get(`/recuiter/${recuiterId}`).then((response) => {
                // navigate("/")
                // if (redirectTo == "/recuiter-profile/post_a_project") {
                //   return navigate(redirectTo)
                // } else if (response?.data?.status == 0) {
                //   return navigate('/HireFreeLancer/personal-details')
                // } else {
                //   navigate(-1)
                // }

            })


        } catch (error) {
            console.error("Error verifying OTP:", error);

            // Check if the error response contains a custom message
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message, { position: 'top-right' });
            } else {
                // If no custom message, show a generic error message
                toast.error('Something went wrong', { position: 'top-right' });
            }
        } finally {
            // Turn off loading whether API call is successful or not
            setLoading(false);
        }
    };










    const renderFormContent = () => {
        switch (currentPage) {
            case 0:
                return (
                    <div className='project-posting-category-container'>
                        <div className="project-posting-category">
                            <label for="inputEmail4" className="form-label">Category <span style={{ color: "red" }}>*</span></label>
                            <select
                                onChange={handleIndustryChange}
                                //  onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                name='industry' value={formValues?.industry} style={{ boxShadow: "none" }} className="form-select" aria-label="Default select example">
                                <option selected>Select Category</option>
                                {industries?.map((item) => (
                                    <>
                                        <option style={{ textTransform: "capitalize" }} value={item?.id}>{item?.name}</option>
                                    </>
                                ))}


                            </select>
                        </div>
                        <div className="project-posting-category">
                            <label for="inputEmail4" className="form-label">Subcategory <span style={{ color: "red" }}>*</span></label>
                            <select
                                onChange={handleCategoryChange}
                                name="occupation_skill"
                                value={formValues.occupation_skill}
                                disabled={!subcategories.length && !selectedIndustry}
                                style={{ boxShadow: "none" }} className="form-select" aria-label="Default select example">
                                <option selected>Select Sub Category</option>
                                {selectedIndustry && subcategories.length === 0 && (
                                    <option value="" disabled>No records found</option>
                                )}
                                {subcategories?.map((item) => (
                                    <>
                                        <option style={{ textTransform: "capitalize" }} value={item?.name}>{item?.name}</option>
                                    </>
                                ))}


                            </select>
                        </div>
                        <div className="project-posting-category">
                            <label for="inputPassword4" className="form-label">Choose your country<span style={{ color: "red" }}>*</span></label>
                            <select
                                onChange={handleInputChange}
                                //  onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                name='country_name' value={formValues?.country_name} style={{ boxShadow: "none" }} className="form-select" aria-label="Default select example">
                                <option value={''} selected>Select Country</option>
                                {/* {citiesGetData?.map((item) => (
                                    <>
                                        <option value={item?.city_name} style={{ textTransform: "capitalize" }}  >{item?.city_name}</option>
                                    </>
                                ))} */}
                                {countries && countries?.map((item) => {
                                    return (

                                        <option value={item?.country_name} style={{ textTransform: "capitalize" }}  >{item?.country_name}</option>


                                    )
                                })}

                            </select>
                        </div>
                        <div className="project-posting-category">
                            <label for="inputPassword4" className="form-label">Choose your city<span style={{ color: "red" }}>*</span></label>
                            <select
                                onChange={handleInputChange}
                                //  onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                name='city_new' value={formValues?.city_new} style={{ boxShadow: "none" }} className="form-select" aria-label="Default select example">
                                <option value={''} selected>Select city</option>

                                {countryData && countryData?.map((item) => {
                                    return (

                                        <option value={item?.city_name} style={{ textTransform: "capitalize" }}  >{item?.city_name}</option>


                                    )
                                })}

                            </select>
                        </div>
                    </div>


                );

            case 1:
                return (

                    <div className='project-posting-container'>

                        {!apiResponse && (
                            <label>

                                <textarea
                                    type="text"
                                    rows="4"
                                    placeholder="Enter a few bullet points or a full description related to specific requirements of your project."
                                    name="input_prompt"
                                    value={projectdescp?.input_prompt}
                                    onChange={handleDecpChange}
                                />
                            </label>
                        )}




                        {apiResponse && (
                            <div className="api-response-container">
                                <div className='project-posting-container'>
                                    {/* <h2>Project Posting</h2> */}
                                    <label>
                                        Project Title:
                                        <input
                                            type="text"
                                            placeholder='Enter the Project Title  '
                                            name="project_title"
                                            value={formValues.project_title}
                                            onChange={handleInputChange}
                                            style={{ fontSize: "15px" }}
                                        />
                                    </label>
                                    <label  >
                                        <div>
                                            <span> Project Description:</span>
                                            <span className='ms-2'><i style={{ fontWeight: "400", fontSize: "17px" }} >(You can customize the below text)</i></span>
                                        </div>

                                        <textarea
                                            type="text"
                                            rows='20'
                                            placeholder="Describe your project here... (Minimum 100 characters)"
                                            name="project_description"
                                            value={formValues.project_description}
                                            style={{ resize: "none" }}
                                            onChange={handleInputChange}


                                        />
                                    </label>
                                </div>
                                <div>

                                    <DeleteIcon onClick={() => handleConfirmation(false)} style={{ color: "red" }} /> Clear

                                </div>
                            </div>
                        )}

                    </div>
                );
            case 2:
                return (
                    <div className='project-posting-skills-container'>
                        <div className="project-posting-skill">
                            <h4> <i>we recommend to add 4-5 skills for your better connection with our freelancers </i></h4>

                            <div className="skill-add-container">
                                <input
                                    type="text"
                                    value={skill}
                                    fullWidth
                                    onChange={handleChangeskill}
                                    placeholder="Add  more skills "
                                />
                                <div className='skill-expertise-button'><button onClick={handleskillSoftware} className='btn btn-dark'>+Add Skill</button></div>
                            </div>

                        </div>
                        <h5> (The following skills have been added as required for your project, you may add or delete as per your specific requirement.)</h5>
                        <div className='skill-expertise-data'>

                            {manySkills && manySkills.length > 0 && manySkills.map((item, index) => (
                                <>
                                    <div className='software-known-button-conatiner'>
                                        <button key={index} style={{ backgroundColor: "#F5E8DD" }} className='btn btn ms-2 software-known-button'>{item}</button>
                                        <CancelIcon
                                            className='software-konw-icon'

                                            onClick={() => handleSkillDelete(index)}
                                        />
                                    </div>

                                </>
                            ))}

                        </div>
                    </div>
                );
            case 3:
                return (
                    <>
                        {formValues?.country_name === "India" ?
                            <div className='pay-layout'>
                                <div> <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="">What is your preferred payment method? <span style={{ color: "red" }}>*</span> </label>

                                </div>
                                <div className="pay-layout-inner">
                                    <div onClick={HandlePayHour}
                                        className={`pay-hour ${payHour ? 'selected' : ''}`}
                                    >
                                        <h6>Pay by the hour</h6>

                                        <p>Hire based on an hourly rate and pay for hours billed. Best for ongoing work</p>
                                    </div>
                                    <div onClick={handleFixedPrice}
                                        className={`pay-hour ${fixedPrice ? 'selected' : ''}`}
                                    >
                                        <h6>Pay fixed price</h6>
                                        <p>Agree on a price and release payment when  the job is done. Best for  one-off task</p>
                                    </div>

                                </div>
                                <div className='toggle-budget' >
                                    {payHour &&

                                        <>

                                            <div className='select-main-heading'>Price Range Per Hour <Tooltip placement="top" title={
                                                <>
                                                    <div><b>Note:</b></div>
                                                    <div>Typically freelancer with following skillset charge the following rates</div>
                                                    <div> Beginner : 100 - 500 per/hour</div>
                                                    <div> Intermediate : 500+ - 1000 per/hour</div>
                                                    <div> Experienced : 1000+ per/hour</div>

                                                </>
                                            } arrow>
                                                <InfoIcon style={{ color: "grey" }} />
                                            </Tooltip></div>
                                            <div className='per-hour-budget'>
                                                <div className='custom-budget'>
                                                    <p>Minimum Price</p>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">₹</span>
                                                        <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                            placeholder='Enter minimum price'
                                                            value={formValues?.min_perhour || ''}

                                                            name="min_perhour"
                                                            onChange={handleMinBudgetChange} id="minBudget"
                                                            onBlur={(e) => validateMinBudget(e.target.value)}
                                                        />
                                                        <span className="input-group-text">Per/Hour</span>
                                                    </div>

                                                </div>
                                                <div className='custom-budget'>
                                                    <p>Maximum Price</p>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">₹</span>
                                                        <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                            placeholder='Enter maximum price'
                                                            value={formValues?.max_perhour || ''}
                                                            name="max_perhour"
                                                            onChange={handleMaxBudgetChange}
                                                            onBlur={(e) => validateMaxBudget(e.target.value)}
                                                            id="maxBudget"
                                                        />
                                                        <span className="input-group-text">Per/Hour</span>
                                                    </div>

                                                </div>
                                                <div className='custom-budget'>
                                                    <p>Number of hours required for project</p>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text"><AccessTimeFilledIcon /></span>
                                                        <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                            placeholder='Enter number of hours'
                                                            value={formValues?.number_of_hours || ''}
                                                            name="number_of_hours"
                                                            onChange={handlePerhours}
                                                        // onBlur={(e) => validateMaxBudget(e.target.value)}
                                                        // id="maxBudget"
                                                        />
                                                        <span className="input-group-text">Per/Hour</span>
                                                    </div>

                                                </div>
                                            </div>


                                            {validationMsg && <div style={{ color: 'red' }}>{validationMsg}</div>}
                                        </>


                                    }
                                    {fixedPrice &&
                                        <>
                                            <div className='select-main-heading'>Fixed Price Range <Tooltip placement="top" title={
                                                <>
                                                    <div><b>Note:</b></div>
                                                    <br />
                                                    <div> Please consider the number of hours required to be invested in the project. Further, please also consider skill set requirement while determining the fixed price of the project. Typically freelancers with the following skill set charge the following rates:</div>
                                                    <br />
                                                    <div> Beginner : 1000 Fixed Price</div>
                                                    <div> Intermediate : 10,000 Fixed Price</div>
                                                    <div> Experienced : 10000+ Fixed Price</div>
                                                    <br />
                                                    <div> Also please read the <Link style={{ color: "white" }} target='_blank' to={`/term-and-condition/fixed-price-escrow-instruction`}>Term and Condtion </Link>
                                                        carefully.
                                                    </div>


                                                </>
                                            } arrow>
                                                <InfoIcon style={{ color: "grey" }} />
                                            </Tooltip></div>
                                            <div className='per-hour-budget'>
                                                <div className='custom-budget'>
                                                    <p>Minimum Price</p>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">₹</span>
                                                        <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                            placeholder='Enter minimum price'
                                                            value={formValues?.min_fixedprice || ''}


                                                            onBlur={(e) => validateMinBudget(e.target.value)}
                                                            onChange={handleMinBudgetChange} name="min_fixedprice"
                                                            id="minBudget"
                                                        />
                                                        {/* <span className="input-group-text">Per/Hour</span> */}
                                                    </div>
                                                    {/* <input type="text" placeholder='Enter minimum budget' onBlur={handleMinBudgetChange} name="min_perhour" id="minBudget" /> */}
                                                </div>
                                                <div className='custom-budget'>
                                                    <p>Maximum Price</p>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">₹</span>
                                                        <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                            value={formValues?.max_fixedprice || ''}

                                                            placeholder='Enter maximum price' onChange={handleMaxBudgetChange}
                                                            onBlur={(e) => validateMaxBudget(e.target.value)}
                                                            name="max_fixedprice" id="maxBudget"
                                                        />
                                                        {/* <span className="input-group-text">Per/Hour</span> */}
                                                    </div>
                                                    {/* <input type="text" placeholder='Enter maximum budget' onBlur={handleMaxBudgetChange} name="max_perhour" id="maxBudget" /> */}
                                                </div>
                                            </div>
                                            {validationMsg && <div style={{ color: 'red' }}>{validationMsg}</div>}


                                        </>

                                    }



                                </div>

                            </div>

                            :
                            // --------------------------------------- For Other Countries --------------------------------//
                            <div className='pay-layout'>
                                <div> <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="">What is your preferred payment method? <span style={{ color: "red" }}>*</span> </label>

                                </div>
                                <div className="pay-layout-inner">
                                    <div onClick={HandlePayHour}
                                        className={`pay-hour ${payHour ? 'selected' : ''}`}
                                    >
                                        <h6>Pay by the hour</h6>

                                        <p>Hire based on an hourly rate and pay for hours billed. Best for ongoing work</p>
                                    </div>
                                    <div onClick={handleFixedPrice}
                                        className={`pay-hour ${fixedPrice ? 'selected' : ''}`}
                                    >
                                        <h6>Pay fixed price</h6>
                                        <p>Agree on a price and release payment when  the job is done. Best for  one-off task</p>
                                    </div>

                                </div>
                                <div className='toggle-budget' >
                                    {payHour &&

                                        <>

                                            <div className='select-main-heading'>Price Range Per Hour <Tooltip placement="top" title={
                                                <>
                                                    <div><b>Note:</b></div>
                                                    <div>Typically freelancer with following skillset charge the following rates</div>
                                                    <div> Beginner : US$10 - 50 per/hour</div>
                                                    <div> Intermediate : US$50 - 100 per/hour</div>
                                                    <div> Experienced : US$100+ per/hour</div>

                                                </>
                                            } arrow>
                                                <InfoIcon style={{ color: "grey" }} />
                                            </Tooltip></div>
                                            <div className='per-hour-budget'>
                                                <div className='custom-budget'>
                                                    <p>Minimum Price</p>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">$</span>
                                                        <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                            placeholder='Enter minimum price'
                                                            value={formValues?.min_perhour_usd || ''}

                                                            name="min_perhour_usd"
                                                            onChange={handleMinBudgetChange} id="minBudget"
                                                            onBlur={(e) => validateMinBudgetGlobal(e.target.value)}
                                                        />
                                                        <span className="input-group-text">Per/Hour</span>
                                                    </div>

                                                </div>
                                                <div className='custom-budget'>
                                                    <p>Maximum Price</p>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">$</span>
                                                        <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                            placeholder='Enter maximum price'
                                                            value={formValues?.max_perhour_usd || ''}
                                                            name="max_perhour_usd"
                                                            onChange={handleMaxBudgetChange}
                                                            onBlur={(e) => validateMaxBudgetGlobal(e.target.value)}
                                                            id="maxBudget"
                                                        />
                                                        <span className="input-group-text">Per/Hour</span>
                                                    </div>

                                                </div>
                                                <div className='custom-budget'>
                                                    <p>Number of hours required for project</p>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text"><AccessTimeFilledIcon /></span>
                                                        <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                            placeholder='Enter number of hours'
                                                            value={formValues?.number_of_hours || ''}
                                                            name="number_of_hours"
                                                            onChange={handlePerhours}
                                                        // onBlur={(e) => validateMaxBudget(e.target.value)}
                                                        // id="maxBudget"
                                                        />
                                                        <span className="input-group-text">Per/Hour</span>
                                                    </div>

                                                </div>
                                            </div>


                                            {validationMsg && <div style={{ color: 'red' }}>{validationMsg}</div>}
                                        </>


                                    }
                                    {fixedPrice &&
                                        <>
                                            <div className='select-main-heading'>Fixed Price Range <Tooltip placement="top" title={
                                                <>
                                                    <div><b>Note:</b></div>
                                                    <br />
                                                    <div> Please consider the number of hours required to be invested in the project. Further, please also consider skill set requirement while determining the fixed price of the project. Typically freelancers with the following skill set charge the following rates:</div>
                                                    <br />
                                                    <div> Beginner : US$10 </div>
                                                    <div> Intermediate : US$100</div>
                                                    <div> Experienced : US$1000+ </div>
                                                    <br />
                                                    <div> Also please read the <Link style={{ color: "white" }} target='_blank' to={`/term-and-condition/fixed-price-escrow-instruction`}>Term and Condtion </Link>
                                                        carefully.
                                                    </div>


                                                </>
                                            } arrow>
                                                <InfoIcon style={{ color: "grey" }} />
                                            </Tooltip></div>
                                            <div className='per-hour-budget'>
                                                <div className='custom-budget'>
                                                    <p>Minimum Price</p>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">$</span>
                                                        <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                            placeholder='Enter minimum price'
                                                            value={formValues?.min_fixedprice_usd || ''}


                                                            onBlur={(e) => validateMinBudgetGlobal(e.target.value)}
                                                            onChange={handleMinBudgetChange} name="min_fixedprice_usd"
                                                            id="minBudget"
                                                        />
                                                        {/* <span className="input-group-text">Per/Hour</span> */}
                                                    </div>
                                                    {/* <input type="text" placeholder='Enter minimum budget' onBlur={handleMinBudgetChange} name="min_perhour" id="minBudget" /> */}
                                                </div>
                                                <div className='custom-budget'>
                                                    <p>Maximum Price</p>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">$</span>
                                                        <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                            value={formValues?.max_fixedprice_usd || ''}

                                                            placeholder='Enter maximum price' onChange={handleMaxBudgetChange}
                                                            onBlur={(e) => validateMaxBudgetGlobal(e.target.value)}
                                                            name="max_fixedprice_usd" id="maxBudget"
                                                        />
                                                        {/* <span className="input-group-text">Per/Hour</span> */}
                                                    </div>
                                                    {/* <input type="text" placeholder='Enter maximum budget' onBlur={handleMaxBudgetChange} name="max_perhour" id="maxBudget" /> */}
                                                </div>
                                            </div>
                                            {validationMsg && <div style={{ color: 'red' }}>{validationMsg}</div>}


                                        </>

                                    }



                                </div>

                            </div>
                        }

                    </>

                );


            default:
                return (
                    <div>
                        <h2>Your Project submitted</h2>


                    </div>
                );
        }
    };



    const validatePage = () => {
        const toastOptions = {
            position: 'top-left',
            autoClose: 10000,
            hideProgressBar: false,
        };



        if (currentPage === 0) {
            const { industry, occupation_skill, city_new } = formValues;
            if (!industry) {
                toast.error('Please fill the Industry fields', toastOptions);
                // setErrorMessage("Please fill the Industry fields");
                return false;
            } if (!occupation_skill) {
                toast.error('Please fill the Ocuupation skill fields', toastOptions);
                // setErrorMessage("Please fill the Ocuupation skill fields");
                return false;
            } if (!city_new) {
                toast.error('Please fill the city new fields', toastOptions);
                // setErrorMessage("Please fill the city new fields");
                return false;
            }
        }
        if (currentPage === 3) {
            if (formValues?.country_name === "India") {
                // Validation for India
                if (!payHour && !fixedPrice) {
                    toast.error('Please select the option. How do you want to pay?', toastOptions);
                    return;
                }
                if (payHour) {
                    if (!formValues.min_perhour || isNaN(parseFloat(formValues.min_perhour))) {
                        toast.error('Minimum per hour price is required.', toastOptions);
                        return;
                    }
                    if (!formValues.max_perhour || isNaN(parseFloat(formValues.max_perhour))) {
                        toast.error('Maximum per hour price is required.', toastOptions);
                        return;
                    }
                    if (parseFloat(formValues.min_perhour) < 100) {
                        toast.error('Minimum budget per hour should be at least 100.', toastOptions);
                        return;
                    }
                    if (parseFloat(formValues.max_perhour) <= parseFloat(formValues.min_perhour)) {
                        toast.error('Maximum per hour price should be greater than the minimum price.', toastOptions);
                        return;
                    }
                    if (!formValues.number_of_hours) {
                        toast.error('Please fill the number of hours.', toastOptions);
                        return;
                    }
                }
                if (fixedPrice) {
                    if (!formValues.min_fixedprice || isNaN(parseFloat(formValues.min_fixedprice))) {
                        toast.error('Minimum fixed price is required.', toastOptions);
                        return;
                    }
                    if (!formValues.max_fixedprice || isNaN(parseFloat(formValues.max_fixedprice))) {
                        toast.error('Maximum fixed price is required.', toastOptions);
                        return;
                    }
                    if (parseFloat(formValues.min_fixedprice) < 100) {
                        toast.error('Minimum fixed price should be at least 100.', toastOptions);
                        return;
                    }
                    if (parseFloat(formValues.max_fixedprice) <= parseFloat(formValues.min_fixedprice)) {
                        toast.error('Maximum fixed price should be greater than the minimum price.', toastOptions);
                        return;
                    }
                }
            } else {
                // No validation for other countries
                if (!payHour && !fixedPrice) {
                    toast.error('Please select the option. How do you want to pay?', toastOptions);
                    return;
                }
                if (payHour) {
                    if (!formValues.min_perhour_usd || isNaN(parseFloat(formValues.min_perhour_usd))) {
                        toast.error('Minimum per hour price is required.', toastOptions);
                        return;
                    }
                    if (!formValues.max_perhour_usd || isNaN(parseFloat(formValues.max_perhour_usd))) {
                        toast.error('Maximum per hour price is required.', toastOptions);
                        return;
                    }
                    if (parseFloat(formValues.min_perhour_usd) < 10) {
                        toast.error('Minimum budget per hour should be at least 10.', toastOptions);
                        return;
                    }
                    if (parseFloat(formValues.max_perhour_usd) <= parseFloat(formValues.min_perhour_usd)) {
                        toast.error('Maximum per hour price should be greater than the minimum price.', toastOptions);
                        return;
                    }
                    if (!formValues.number_of_hours) {
                        toast.error('Please fill the number of hours.', toastOptions);
                        return;
                    }
                }
                if (fixedPrice) {
                    if (!formValues.min_fixedprice_usd || isNaN(parseFloat(formValues.min_fixedprice_usd))) {
                        toast.error('Minimum fixed price is required.', toastOptions);
                        return;
                    }
                    if (!formValues.max_fixedprice_usd || isNaN(parseFloat(formValues.max_fixedprice_usd))) {
                        toast.error('Maximum fixed price is required.', toastOptions);
                        return;
                    }
                    if (parseFloat(formValues.min_fixedprice_usd) < 10) {
                        toast.error('Minimum fixed price should be at least 10.', toastOptions);
                        return;
                    }
                    if (parseFloat(formValues.max_fixedprice_usd) <= parseFloat(formValues.min_fixedprice_usd)) {
                        toast.error('Maximum fixed price should be greater than the minimum price.', toastOptions);
                        return;
                    }
                }
            }
        }

        if (currentPage === 4) {
            const { email, } = formValues;
            if (!email) {
                toast.error('Please enter your email and verify ', toastOptions);
                // setErrorMessage("Please fill the Industry fields");
                return false;
            }
        }


        return true;
    };

    return (
        <>
            {loading && <LoadingPannel />}
            <div className="global-projectpost">
                <div className="global-project-post-container">
                    <div className="global-flex1">
                        <div className="post-project-logo"><Link to={'/'}><img src="/image/nbflogo.png" alt="" /></Link> </div>
                        <div className='project-post-heading'>


                            <h1>What are your requirements? <br /> Let us <span style={{ color: "#FF3131" }}>know</span> </h1>
                            <p>We will help you  create the perfect brief. The more detail you provide the better.</p>




                        </div>


                        {/* <textarea name="" rows='4' placeholder='Enter a few bullet points or a full description.' id=""></textarea> */}
                        <div className="form-content">{renderFormContent()}</div>

                        <div className='project-button'>
                            {currentPage > 0 && (
                                <button onClick={handlePrev} className="btn btn-secondary">
                                    Previous
                                </button>
                            )}

                            {currentPage < totalPages - 1 && (
                                <button
                                    type="button"
                                    onClick={handleNext}
                                    className={`btn btn-primary ${currentPage === 1 && !apiResponse ? '' : ''}`}
                                >
                                    Next
                                </button>
                            )}

                            {currentPage === totalPages - 1 && (
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    className="btn btn-success"
                                >
                                    Save
                                </button>
                            )}



                        </div>
                    </div>
                    <div className="global-flex2">
                        <img src="/image/projectpostingimg.jpg" alt="" />
                    </div>

                </div>
                <ToastContainer />
            </div>
        </>

    )
}




export default ProjectPostLogin

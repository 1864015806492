import React, { lazy, Suspense, useEffect, useState } from 'react'
import Routers from './Route/Index';
import "./App.css";
import { Provider } from "react-redux";
import store from "./store/index"
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutRoutes from './Route/LayoutRoutes';
import SuccessPaymentNonit from './components/ReferToEarn/SuccessPaymentNonit';
import SuccessPaymentGlobal from './components/PaymentOutside/SuccessPaymentGlobal';
import axios from 'axios';
 
const LoadingPannel = lazy(()=>import("./commonComponent/LoadingPannel/LoadingPannel"));
const SuccessPayementJobPlain = lazy(()=>import("./components/recuiterPaymentlink/SuccessPayementJobPlain"));
const SuucessPayment = lazy(()=>import("./components/ReferToEarn/SuucessPayment"));
const NotFound = lazy(()=>import("./components/NotFound/NotFound"));
 



const App = () => {

  const [location, setLocation] = useState({});
  console.log("latamdlon",location)
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/"); // or any other IP-based geolocation API
        setLocation(response?.data?.country_name);
        console.log("cheeckLocation",response?.data?.country_name);
      } catch (err) {
        setError("Failed to fetch IP-based location.");
      }
    };

    fetchLocation();
  }, []);

  

 

 
  return (
    <>
      {/* <Provider store={store}>
     <Routers />
     
     </Provider> */}
      <Provider store={store}>
        <BrowserRouter basename='/'>
          {/* <Suspense fallback={<div><LoadingPannel/></div>}> */}


            <Routes>
              <Route path="/*" element={<LayoutRoutes />} />
              <Route path="/payment-successfully-done" element={<SuccessPayementJobPlain />} />
              <Route path="/success-payment" element={<SuucessPayment />} />
              <Route path="/successfully-payment-done" element={<SuccessPaymentNonit />} />
              <Route path="/successfully-payment-done-global" element={<SuccessPaymentGlobal />} />
              <Route path="/404" element={<NotFound />} />
            </Routes>
          {/* </Suspense> */}
        </BrowserRouter>
      </Provider>
    </>
  )
}

export default App